const useClientSupportLink = (): string => {
    const { getCurrentLocale } = useTranslation();
    const locale = getCurrentLocale();

    if (locale === 'be-fr') {
        return 'https://pro.trustup.be/fr/support/';
    }
    if (locale === 'be-nl') {
        return 'https://pro.trustup.be/nl/klantensupport/';
    }
    if (locale === 'fr-fr') {
        return 'https://pro.trustup.fr/support/';
    } else {
        return 'https://pro.trustup.be/fr/support/';
    }
};

export default useClientSupportLink;
